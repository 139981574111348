button {
  color: rgba(0, 0, 0, 0.9) !important;
}

button[aria-selected="true"] {
  color: #ff7b25 !important;
}

.casopisi {

}

.casopisiTab {
  padding: 10px;
}

.italic {
  font-style: italic;
}
