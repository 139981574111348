.footer {
  width: 100%;
  min-height: 300px;
  background-color: #f0efef;
  border-top: 2px solid #ff7b25;
  bottom: 0px;
  margin: 0px;

  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
  color: rgba(255, 255, 255, 0.55);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}


.link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.55);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  text-align: center;
}

.link:hover {
  color: #696969;
}

.linkColor a {
  color: rgba(255, 255, 255, 0.55);
}

.center {
  text-align: center !important;
}

.footerInfo {
  text-align: center !important;
}

.footerInfo p {
  text-align: center !important;
}

.footerInfo img {
  max-height: 100px;
  width: auto;
  padding: 10px;
  text-align: center;
  margin: auto;
  display: inline-block;
}

.socialMediaImg {
  width: 50px !important;
  height: auto !important;
  padding: 5px;
}

.nopaddingMargin {
  padding: 0px !important;
  margin: 0px !important;
}

.copyRight {
  font-size: 0.7em;
  text-align: center;
  padding-bottom: 10px;
}
