.predmet {
  width: 30%;
  margin: 10px;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.0095);
  padding: 0px;
  height: 250px;
  border:1px solid #f8f9fa !important;
}

.predmetContent {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}

.front {
  font-size: 1.1em;
  text-align: center;
  width:100%;
  height: 100%;
  padding: 10px;
  position: absolute;
}

.back {
  width:100%;
  height: 100%;
  padding: 10px;
  display: none;
  position: absolute;
}

.predmet:hover .front {
  display: none;
}

.predmet:hover .back {
  display: block;
}

.predmetIcon {
  width: 100px;
  height: auto;
  margin: 0px auto 10px auto;
  text-align: center;
  border-radius: 50%;
}

.predmetIconLarge {
  width: 200px;
  height: auto;
  margin: 0px auto 10px auto;
  text-align: center;
  border-radius: 50%;
}

.bold {
  font-weight: bold;
}

.left {
  text-align: left;
}

.justify {
  text-align: justify;
}

.center {
  text-align: center;
}


.link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  text-align: center;
}

.link:hover {
  color: #696969;
}

.learnMoreContainer {
  position: absolute;
  width: 100%;
  height: 100%;
}


.learnMoreLink {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  text-align: center;
  display: inline-block;
  border: 1px solid #696969;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  top: calc(50% - 55px);

}

.learnMoreLink:hover {
  color: #696969;
}

.learnMoreLink:hover img {
  opacity: 0.8;
}


@media (max-width: 992px) {
  .predmet {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .predmet {
    width: 45%;
  }

  .predmetIconLarge {
    width: 180px;
  }
}

@media (max-width: 576px) {
  .predmet {
    width: 100%;
    margin: 10px auto 10px auto;
  }

  .predmetIconLarge {
    width: 110px;
  }
}
