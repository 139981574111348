.contactContent {
  padding: 0px;
}
.padding {
  padding: 0px !important;
}

#googlemap {
  width: 100%;
  min-height: 400px;
}

#googlemap iframe {
  width: 100%;
}

.link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  text-align: center;
}

.link:hover {
  color: #696969;
}
