.slideShowContainer {
  width:100%;
  height: 650px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0px !important;
}

.slideShowContainer img {
  height: 650px;
  width: auto;
  margin: auto;
  padding: 0px;
}

.title {
  text-align: center !important;
}

.text {
  text-align: center !important;
}

.carouselImg {
  text-align: center;
  margin: auto;
  display: block;
}

@media (max-width: 992px) {
  .slideShowContainer {
    height: 600px;
  }

  .slideShowContainer img {
    height: 600px;
  }
}

@media (max-width: 768px) {
  .slideShowContainer {
    height: 500px;
  }

  .slideShowContainer img {
    height: 500px;
  }

}

@media (max-width: 576px) {
  .slideShowContainer {
    max-height: 500px;
  }

  .slideShowContainer img {
    max-height: 500px;
  }
}
