html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-family: Arial, Helvetica, sans-serif;/*'Poppins', fantasy, Arial; /* "Comic Sans MS", cursive, sans-serif !important;*/
  background-color: white;/*#f2f2f2; /*#F5F5F5*/
  font-size: 1em;
  text-align: justify;
  color: #212121;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
