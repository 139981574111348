.logoSekcije {
  display: block;
  width:  200px;
  height: auto;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
}

.posterSection {
  display: flex;
}

.wrap    {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.poster {
  width: 30%;
}

.poster img {
  width: 100%;
  height: auto;
  padding: 10px;
}

.link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  display: block;
}

.link:hover {
  color: gray;
}

.coverImage {
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 10px 0px 10px 0px;
}
/*---Media Queries --*/
@media (max-width: 992px) {

}

@media (max-width: 768px) {
  .poster {
    width: 45%;
  }

}

@media (max-width: 576px) {
  .poster {
    width: 100%;
  }
}
