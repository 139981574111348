.news {
  margin-bottom: 10px;
}

.newsImg {
  max-height: 170px;
}

.newsHeader {
  text-align: right !important;
}

.newsBody {
  text-align: justify;
}

/*---Media Queries --*/
@media (max-width: 992px) {
  .newsImg {
    max-height: 150px;
  }
}

@media (max-width: 768px) {

  .newsImg {
    max-height: 120px;
  }
}

@media (max-width: 576px) {
  .newsImg {
    max-height: 100px;
  }
}
