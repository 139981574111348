.App {
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 1em;
}

.backgroundDiv {
  margin: 0px;
  padding: 0px;
  background-color: rgba(0, 0, 0, 0.0125);
  background-image: url('./bbgray.jpg');
  background-repeat: no-repeat;
	background-position: left top;
  background-attachment: fixed;
	background-size: cover;
  z-index: -200;
}

.content {
  border: none;
  border-left: 1px solid #ff7b25;
  border-right: 1px solid #ff7b25;
  border-radius:0px;
  max-width: 1200px;
  min-height: 400px;
  margin: auto;
  background-color: white;
  padding: 0px !important;
}

.padding {
  padding: 10px;
}

.noPaddingMargin {
  padding: 0px !important;
  margin: 0px !important;
}

/*---Media Queries --*/
@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .App {
      background-image: none;
    }
}

@media (max-width: 576px) {
  .App {
    background-image: none;
  }

  .content {
    border: none;
  }
}
