.header {
  padding: 0px;
  margin: 0px;
  border-bottom: 2px solid #ff7b25;
}

.navigation {
  background-color: #A9A9A9 !important;
  color: white;
  padding: 5px;
}

.text-shadow {
  text-shadow: 1px 1px 10px #222;
}

.padding {
  padding: 20px;
}


.logoimg {
  max-height: 90px;
  width: auto;
  padding: 5px;
}

.content {
  margin: auto;
  max-width: 1200px;
}

/*---Media Queries --*/
@media (max-width: 992px) {
  .navbar {
    padding-right: 10px;
  }

}

@media (max-width: 768px) {
  .navbar {
    padding-right: 8px;
  }

  .padding {
    padding: 10px;
  }
  .dropdown-menu {
      min-width: 300px;
  }

}

@media (max-width: 576px) {
  .navbar {
    padding-right: 0px;
  }

  .padding {
    padding: 5px;
  }

  .dropdown-menu {
    max-width: 96%;
  }

}




/*---Firefox Bug Fix --*/
.carousel-item {
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
}
/*--- Fixed Background Image --*/
figure {
  position: relative;
  width: 100%;
  height: 60%;
  margin: 0!important;
}
.fixed-wrap {
  clip: rect(0, auto, auto, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*--- Bootstrap Padding Fix --*/
[class*="col-"] {
    padding: 1rem;
}
