.pravilnici h3{
  padding-bottom: 20px;
}

.container {
  display: flex;
}

.containetBetween {
  justify-content: space-between;
}

.containerStart {
  justify-content: flex-start;
}

.containCenter {
  justify-content: center;
}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.pdfFile {
  width: 150px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
}

.pdfFile a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.pdfFile:hover a {
  text-decoration: none;
  color: #696969;
}

.pdfIcon {
  margin: auto;
  text-align: center;
}

.pdfIcon img {
  width: 90px;
  height: auto;
  text-align: center;
  margin: auto;

}

.pdfText {
  text-align: center;
}

/*---Media Queries --*/
@media (max-width: 992px) {

}

@media (max-width: 768px) {
  .pdfFile {
    width: 140px;
  }

  .pdfIcon img {
    width: 80px;
    height: auto;
  }

}

@media (max-width: 576px) {
  .pdfFile {
    width: 130px;
  }

  .pdfIcon img {
    width: 80px;
    height: auto;
  }
}
