.profesor {
  max-width: 30%;
  margin: 10px;
  display: inline-flex;
  background-color: #FFFFFF;
}

.white {
  background-color: #FFFFFF;
}

.avatar {
  width: 200px;
  height: auto;
  margin: auto;
  text-align: center;
  border-radius: 50%;
}
.center {
  text-align: center;
  margin: auto;
}

.learnMoreLink {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  text-align: center;

}

.learnMoreLink:hover {
  color: #696969;
}

.learnMoreLink:hover img {
  opacity: 0.8;
}

@media (max-width: 992px) {
  .profesor {
    max-width: 30%;
  }
}

@media (max-width: 768px) {
  .profesor {
    max-width: 45%;
  }

}

@media (max-width: 576px) {
  .profesor {
    min-width: 100%;
    margin: 10px auto 10px auto;
  }
}
