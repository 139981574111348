.javneNabavke a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  padding: 10px;
}

.javneNabavke a:hover {
  color: gray;
}

.title {
  text-decoration: none;
}

.icon {
  height: 50px;
  width: auto;
  padding: 5px;
}
