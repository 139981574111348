.goBack {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  text-align: center;
  border: 1px solid #EBECE6;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  color: #838381;
  display: block;
  font-weight: bold;
  max-width: 200px;
  margin: 10px auto 10px auto;
}

.goBack:hover {
  color: #121212;
  background-color: #f3f3f3;
}

.date {
  color: rgba(0, 0, 0, 0.5);
  font-style: italic;
}

.link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  text-align: center;
  font-style: italic;

}

.link:hover {
  color: #696969;
}

.pdfIcon {
  height: 40px;
  width: auto;
  padding: 5px 10px 5px 0px;
}

.newsImageLogo {
  height: 50px;
  width: auto;
  padding: 5px;
}
