.avatarImg {
  width: 200px;
  height: auto;
  margin: auto;
  text-align: center;
  border-radius: 50%;
}

.personalData p{
  text-align: center;
}

.nameText {
  font-size: 1.3em;
  font-weight: bolder;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.nameText:hover {
  color: gray;
}

.bottomPadding {
  padding-bottom: 20px;
}

.paddingTop {
  padding-top: 20px;
}

.learnMoreLink {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  text-align: center;
  border: 1px solid #EBECE6;
  border-radius: 5px;
  background-color: white;
  padding: 10px;
  color: #838381;
  display: block;
  font-weight: bold;
  max-width: 150px;
  margin: 10px auto 10px auto;
}

.learnMoreLink:hover {
  color: #121212;
  background-color: #f3f3f3;
}

.centerAlign {
  text-align: center;
  margin: auto;
}

.predmetiContainer {
  display: flow-root;
}
