.img {
	height: 200px;
	width: 200px;
	/*border-radius: 50%;*/
}

.textimg {
	height: auto;
	width: 80%;
	border: 0;
	margin: auto;
	text-align: center;
	display: block;
  padding-bottom: 10px;
	border-radius: 20px;
}

p {
	text-align: justify;
	line-height: 1.7em;
}

.imgcontainer {
	position: relative;
  text-align: center;
  color: white;
	display: inline-block;
}

.imgcontainer img {
	height: 150px;
	width: 150px;
	border-radius: 50%;
  padding: 5px;
}

.imgtextcentered {
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
	display: none;
	font-weight: bold;
	font-size: 1.3em;
}

.imgcontainer:hover {
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 50%;
}

.imgcontainer:hover img {
	opacity: 0.5;
}

.imgcontainer:hover .imgtextcentered{
	display: inline-block;
}

/*---Media Queries --*/
@media (max-width: 992px) {
	.textimg {
		height: auto;
		width: 85%;
  }
}

@media (max-width: 768px) {

	.textimg {
		height: auto;
		width: 90%;
  }
}

@media (max-width: 576px) {
	.textimg {
		height: auto;
		width: 95%;
  }
}
