.news {
  margin-bottom: 10px;
  font-size: 1em;
}

.newsImg {
  max-height: 170px;
}

.newsHeader {
  text-align: right !important;
  background-color: #FFF !important;
  color: rgba(0, 0, 0, 0.55) !important;
  padding: 5px 0px 5px 0px;
  font-style: italic;
}

.newsBody {
  text-align: justify;
}

.learnMore {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

.learnMore:hover {
  color: #696969;
}

.newsTitle {
  font-size: 1.2em;
}

.newsImageLogo {
  height: 20px;
  width: auto;
  float: left;
}

/*---Media Queries --*/
@media (max-width: 992px) {
  .newsImg {
    max-height: 150px;
  }
}

@media (max-width: 768px) {

  .newsImg {
    max-height: 120px;
  }
}

@media (max-width: 576px) {
  .newsImg {
    max-height: 100px;
    font-size: 0.7em;
  }

}
