.sidePanel {
  border-left: 1px solid #ff7b25;
  padding-left: 10px;
  font-size: 1em;
}

.linkColor a {
  color: rgba(0, 0, 0, 0.9);
}

.smallText {
  font-size: 0.83em !important;
}

.padding {
  padding: 10px;
}

.sidePanel a {
  padding: 5px 0px 5px 0px;
}

/*---Media Queries --*/
@media (max-width: 992px) {

}

@media (max-width: 768px) {
  .sidePanel {
    display: none !important;
  }
}

@media (max-width: 576px) {
  .sidePanel {
    display: none !important;
  }
}
