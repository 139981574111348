.notFound404 {
  min-height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px;
  padding: 0px;
}

.image404 {
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 0px;
}
