.center {
  text-align: center;
}

.padding {
  padding: 10px;
}

.ClassScheduleTime table th,  .ClassScheduleTime table td {
  padding: 20px;
}

/*---Media Queries --*/
@media (max-width: 992px) {

}

@media (max-width: 768px) {
  .ClassScheduleTime table th,  .ClassScheduleTime table td {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .ClassScheduleTime table th,  .ClassScheduleTime table td {
    padding: 10px;
  }
}
