.sidePanelLinks {
}


.sidePanelLinks img {
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.sidePanelLinks a {
  text-decoration: none;
  --color: rgba(0, 0, 0, 0.9);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  display: block;
}

.sidePanelLinks a:hover {
  color: gray;
}


/*---Media Queries --*/
@media (max-width: 992px) {

}

@media (max-width: 768px) {

  .sidePanelLinks img {
    border-radius: 5px;
    width: 70%;
    height: auto;
  }
}

@media (max-width: 576px) {

}
